import * as React from 'react'
import { Link, PageProps } from 'gatsby'
import { PC, SP } from '../utils/media-query'
import Button from '../atoms/TheButton'
import Menus from '../molecules/Menus'
import BurgerMenu from '../molecules/BurgerMenu'
import 'scss/header.scss'
const logo = require('images/logo.png')

type HeaderProps = {
  isTopPage?: boolean
}

type HeaderState = {
  style: string
}

export default class Header extends React.Component<
  HeaderProps,
  HeaderState,
  PageProps
> {
  constructor(props: HeaderProps) {
    super(props)

    this.state = {
      style: props.isTopPage
        ? 'header__upper'
        : 'header__lower',
    }
  }

  render() {
    return (
      <header className={`${this.state.style} header`}>
        <Link to="/" className="header__link">
          <div className="brand">
            <img src={logo} />
            <div className="title">
              <p>日常に、ちょっと楽しいヒトコマを.</p>
              <h3>
                <span className="under_yellow">
                  ニチコマ合同会社
                </span>
              </h3>
            </div>
          </div>
        </Link>
        <div className="nav">
          <SP>
            <BurgerMenu
              isTopPage={this.props.isTopPage}
            ></BurgerMenu>
          </SP>
          <PC>
            <Menus isTopPage={this.props.isTopPage} />
            <Button
              type="square"
              size="small"
              to={
                this.props.isTopPage
                  ? '#contact'
                  : '/#contact'
              }
              elevation="1"
              icon
            >
              ご相談・お見積もり
            </Button>
          </PC>
        </div>
      </header>
    )
  }
}
