import * as React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  IconPrefix,
  IconName,
} from '@fortawesome/fontawesome-svg-core'
import { SP } from '../utils/media-query'
const styles = require('scss/menus.module.scss')

type Menu = {
  name: string
  path: string
}

type MenuProps = {
  isTopPage?: boolean
  onClick?: () => void
}

type IconProp = [IconPrefix, IconName]

const Menus = ({ isTopPage, onClick }: MenuProps) => {
  const navItems: Array<Menu> = [
    { name: '企業概要', path: '/company-profile' },
    { name: '合同会社とは？', path: '/about-llc' },
    { name: '開発実績・料金表', path: '/achievements' },
    { name: '採用情報', path: '/recruit' },
  ]
  const style = isTopPage
    ? 'nav-list__upper-layer'
    : 'nav-list__lower-layer'
  const fa: IconProp = ['fas', 'chevron-right']
  return (
    <ul className="nav-list">
      {navItems.map((item, index) => {
        return (
          <li
            key={index}
            className={`nav-list-item menu-item ${style}`}
            onClick={() =>
              onClick ? onClick() : undefined
            }
          >
            <Link to={item.path} className={styles.link}>
              {item.name}
              <SP>
                <FontAwesomeIcon
                  className="icon"
                  color="#1DB2D7"
                  icon={fa}
                />
              </SP>
            </Link>
          </li>
        )
      })}
    </ul>
  )
}
export default Menus
