import { useMediaQuery } from 'react-responsive'

export const PC = ({ children }: { children: any }) => {
  const isDesktop = useMediaQuery({ minWidth: 769 })
  return isDesktop ? children : null
}
export const SP = ({ children }: { children: any }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 })
  return isMobile ? children : null
}
