import * as React from 'react'
import Button from '../atoms/TheButton'
import 'scss/menuCard.scss'
const logo = require('images/logo.png')

type Props = {
  isActive: Boolean
  toggle: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void
  children?: React.ReactNode
}

type State = {
  vh: any
}

class MenuCard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { vh: '100vh' }
  }
  componentDidMount() {
    if (typeof window !== `undefined`) {
      this.setState({ vh: window.innerHeight + 'px' })
    }
  }
  render() {
    return (
      <>
        <div
          className={this.props.isActive ? 'open' : null}
        >
          <nav
            style={{
              height: this.state.vh,
            }}
          >
            <div className="brand">
              <img src={logo} />
              <div className="title">
                <h3>ニチコマ合同会社</h3>
              </div>
            </div>
            <div className="inner">
              {this.props.children}
              {/* <div className="action">
                <Button elevation="1" icon>
                  採用ページへ
                </Button>
              </div> */}
            </div>
          </nav>
          <div id="mask" onClick={this.props.toggle} />
        </div>
      </>
    )
  }
}

export default MenuCard
