import * as React from 'react'
import {
  ButtonTypes,
  ButtonSizes,
  IndicatorTypes,
} from '../utils/prop-types'
import 'scss/button.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  IconPrefix,
  IconName,
} from '@fortawesome/fontawesome-svg-core'
import { Link } from 'gatsby'

const classNames = require('classnames')

type Props = {
  type?: ButtonTypes
  size?: ButtonSizes
  icon?: boolean
  color?: string
  elevation?: string
  indicator?: IndicatorTypes
  submit?: boolean
  to: string
}

type IconProp = [IconPrefix, IconName]

export default class Button extends React.Component<Props> {
  static defaultProps: Props = {
    type: 'ellipse',
    size: 'medium',
    icon: false,
    color: 'primary',
    elevation: undefined,
    indicator: undefined,
    submit: false,
    to: undefined,
  }

  render() {
    const {
      type,
      size,
      icon,
      color,
      elevation,
      indicator,
      children,
    } = this.props

    const elevationClassName = elevation
      ? `elevation-${elevation}`
      : 'elevation-4'

    const fa: IconProp = icon
      ? ['fas', 'angle-right']
      : undefined

    const btnClass = classNames(
      'button',
      elevationClassName,
      {
        [type]: type,
        [size]: size,
        [color]: color,
        [indicator]: indicator,
      },
    )
    const Btn = () => (
      <button
        type={this.props.submit ? 'submit' : 'button'}
        className={btnClass}
      >
        {children}
        {fa ? (
          <FontAwesomeIcon className="icon" icon={fa} />
        ) : null}
      </button>
    )
    return this.props.to ? (
      <Link to={this.props.to}>
        <Btn />
      </Link>
    ) : (
      <Btn />
    )
  }
}
