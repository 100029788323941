import * as React from 'react'
import { HamburgerCollapse } from 'react-animated-burgers'
import MenuCard from './MenuCard'
import Menus from './Menus'

type BurgerMenuProps = {
  isTopPage: boolean
}

export default class BurgerMenu extends React.Component<
  BurgerMenuProps
> {
  constructor(props: BurgerMenuProps) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  state = {
    isActive: false,
  }

  handleClick = () => {
    this.setState({
      isActive: !this.state.isActive,
    })
    this.state.isActive
      ? document.body.removeAttribute('style')
      : document.body.setAttribute(
          'style',
          'overflow: hidden;',
        )
  }
  styles = {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    backgroundColor: 'none',
    outline: 'none',
    zIndex: '900',
  }

  render() {
    return (
      <>
        <HamburgerCollapse
          buttonWidth={20}
          isActive={this.state.isActive}
          toggleButton={this.handleClick}
          buttonStyle={this.styles}
          barColor="#063757"
        />
        <MenuCard
          isActive={this.state.isActive}
          toggle={this.handleClick}
        >
          <Menus
            isTopPage={this.props.isTopPage}
            onClick={this.handleClick}
          />
        </MenuCard>
      </>
    )
  }
}
